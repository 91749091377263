import React, { useRef, useState, useEffect, useContext } from "react";
import css from "../styles/Container.module.scss";
import classNames from "classnames";
import anime from "animejs";

const IncraceChance = ({ setMenu, chance }) => {
	const divRef = useRef();
	const language = false;

	const [percent, setPercent] = useState(0);

	useEffect(() => {
		if (!chance) return;
		const perc = +chance;

		setTimeout(() => {
			anime({
				targets: divRef.current,
				innerHTML: [0, perc],
				easing: "linear",
				duration: perc >= 0 && perc <= 25 ? 1000 : perc > 25 && perc < 50 ? 2000 : 2500,
				round: 100,
				update: function (anim) {
					divRef.current.innerHTML = `${parseFloat(anim.animations[0].currentValue).toFixed(2)}%`;
					setPercent(parseFloat(anim.animations[0].currentValue).toFixed(2));
				},
			});
		}, 500);
	}, [chance]);

	return (
		<section className={css.bottom}>
			<div className={css.progressLineContainer}>
				<div className={css.progress}>
					<div
						className={classNames(
							css.line,
							percent <= 39.99 && css._red,
							percent >= 40 && percent <= 79 && css._gold,
							percent >= 79.01 && css._green,
						)}
						style={{
							width: `${percent}%`,
						}}
					></div>
				</div>

				<div className={css.chanceContainer}>
					<div
						className={css.banner}
						onClick={() => setMenu(5)}
					>
						{!language && <span>increase chance</span>}
						{!!language && <span>chance badhao</span>}
					</div>
					<div className={css.proc}>
						<span ref={divRef}></span>
						{!language && <span>Chance of winning</span>}
						{!!language && <span>Jeetne ka chance</span>}
					</div>
				</div>
			</div>
		</section>
	);
};

export default IncraceChance;
