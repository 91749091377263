/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import css from "../styles/Container.module.scss";
import classNames from "classnames";
import numeral from "numeral";

const PreviusRound = ({ users, winners }) => {
	const [usersCount, setUsersCount] = useState();
	const [stage, setStage] = useState(0);
	const [winnersCount, setWinnersCount] = useState();
	const [stage2, setStage2] = useState(0);

	useEffect(() => {
		const usersString = numeral(users).format("0,0");
		const timeoutId = setTimeout(() => {
			setStage(1);

			const timeoutId2 = setTimeout(() => {
				setUsersCount(usersString);
				setStage(0);
			}, 500);

			return () => clearTimeout(timeoutId2);
		}, 500);

		return () => clearTimeout(timeoutId);
  }, [users]);
  
	useEffect(() => {
		const winnersString = numeral(winners).format("0,0");
		const timeoutId2 = setTimeout(() => {
			setStage2(1);

			const timeoutId22 = setTimeout(() => {
				setWinnersCount(winnersString);
				setStage2(0);
			}, 500);

			return () => clearTimeout(timeoutId22);
		}, 500);

		return () => clearTimeout(timeoutId2);
	}, [winners]);

	return (
		<div className={css.stats}>
			<div className={css.sec1}>
				<div className={css.title}>
					<span>PREVIUS</span>
					<span>ROUND</span>
				</div>
			</div>
			<div className={classNames(css.sec2, stage === 1 && css._rotate)}>
				<div className={css.title}>
					<span>{numeral(usersCount).format("0,0")}</span>
					<span>users</span>
				</div>
			</div>
			<div className={classNames(css.sec3, stage2 === 1 && css._rotate)}>
				<div className={css.title}>
					<span>{numeral(winnersCount).format("0,0")}₹</span>
					<span>winnings</span>
				</div>
			</div>
		</div>
	);
};

export default PreviusRound;
