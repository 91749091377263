/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import css from "./styles/Menu.module.scss";
import classNames from "classnames";
import { useNavigate, Link } from "react-router-dom";
import Container from "./components/Container";
import IncreaseChance from "./components/OtherPages/IncreaseChance";
import Help from "./components/OtherPages/Help";
import HowToPlay from "./components/OtherPages/HowToPlay";
import History from "./components/OtherPages/History";
import PredictionLimit from "./components/OtherPages/PredictionLimit";
import FramePlay from "./components/OtherPages/FramePlay";
import FrameDeposit from "./components/OtherPages/FrameDeposit";

const App = () => {
	const navigate = useNavigate();
	const [lang, setLang] = useState(false);
	const [menu, setMenu] = useState(1);
	const [leadData, setLeadData] = useState({});
	const [coeffsHistory, setCoeffsHistory] = useState([]);

	const [chance, setChance] = useState(0);
	const [limit, setLimit] = useState({});

	const [isLoading, setIsLoading] = useState(true);
	const videoRef = useRef(null);
	const [play, setPlay] = useState(false);

	useEffect(() => {
		const config = JSON.parse(localStorage.getItem("configDemo"));
		if (!config || !Object.keys(config).length) return navigate("/config");
		setLeadData(config);
	}, []);

	const handlePlayPause = () => {
		setPlay(true);
		videoRef.current?.seekTo(0);
	};

	return (
		<>
			{isLoading && (
				<div className="uiLoaderContainer">
					<span className="loader"></span>
				</div>
			)}
			<section className={classNames(css.head, "head")}>
				<Link to="/config">
					<div className={css.logo} />
				</Link>
				<div className={css.languages}>
					<div className={css.languageContainer}>
						<div className={classNames(css.language, !lang && css._active)}>ENG</div>
						<div className={classNames(css.language, lang && css._active)}>HIN</div>
					</div>
				</div>
			</section>
			<FramePlay
				menu={menu}
				setMenu={setMenu}
				leadData={leadData}
				videoRef={videoRef}
				play={play}
				setIsLoading={setIsLoading}
			/>
			<FrameDeposit
				menu={menu}
				setMenu={setMenu}
				leadData={leadData}
			/>
			<IncreaseChance
				menu={menu}
				setMenu={setMenu}
				leadData={leadData}
				chance={chance}
				limit={limit}
			/>
			<Help
				menu={menu}
				setMenu={setMenu}
				leadData={leadData}
			/>
			<HowToPlay
				menu={menu}
				setMenu={setMenu}
				leadData={leadData}
			/>
			<History
				menu={menu}
				setMenu={setMenu}
				leadData={leadData}
				coeffsHistory={coeffsHistory}
			/>
			<PredictionLimit
				menu={menu}
				setMenu={setMenu}
				leadData={leadData}
				chance={chance}
				limit={limit}
			/>
			<div className="uiContainer">
				<div className="uiBgElement" />
				<Container
					setMenu={setMenu}
					menu={menu}
					setCoeffsHistory={setCoeffsHistory}
					setChance={setChance}
					chance={chance}
					setLimit={setLimit}
					limit={limit}
					handlePlayPause={handlePlayPause}
				/>
			</div>
			<div className={classNames(css.menuContainer, "menu")}>
				<div className={css.menu}>
					<div
						className={classNames(css.item, menu === 1 && css._active)}
						onClick={() => setMenu(1)}
					>
						<span>AVIASOFT</span>
					</div>
					<div
						className={classNames(css.item, menu === 3 && css._active)}
						onClick={() => setMenu(3)}
					>
						<span>HOW TO PLAY</span>
					</div>
					<div
						className={classNames(css.item, menu === 2 && css._active)}
						onClick={() => setMenu(2)}
					>
						<span>HELP ME</span>
					</div>
					<div
						className={classNames(css.item, menu === 4 && css._active)}
						onClick={() => setMenu(4)}
					>
						<span>HISTORY</span>
					</div>
				</div>
			</div>
		</>
	);
};

export default App;
