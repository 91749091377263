import React, { useEffect, useState } from "react";
import css from "../styles/PredictionLimit.module.scss";
import classNames from "classnames";
import { DateTime } from "luxon";

const PredictionLimit = ({ setMenu = null, limit, title = true }) => {
	const [time, setTime] = useState("");

	useEffect(() => {
		setInterval(displayCurrentTime, 1000);
	}, []);

	const displayCurrentTime = () => {
		const now = DateTime.now().setZone("Europe/Kyiv");

		const midnight = now.endOf("day");

		const diff = midnight.diff(now, ["hours", "minutes"]);

		const timeLeft = diff.toFormat("hh:mm");
		const timeLeftWithSec = diff.toFormat("hh:mm:ss");

		setTime(timeLeftWithSec);
	};

	return (
		<section className={css.container}>
			<div className={css.header}>
				{title && (
					<span
						onClick={() => {
							if (setMenu !== null) setMenu(6);
						}}
					>
						prediction limit
					</span>
				)}
				{setMenu !== null && <span className={css.icon}></span>}
			</div>
			<div className={css.body}>
				<div className={css.limit}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="9"
						height="14"
						fill="none"
						viewBox="0 0 9 14"
					>
						<path
							fill="#F9C41C"
							d="m4.302 8.895.085-.604H0L4.654 0l-.488 4.707-.085.604H8.47l-4.654 8.291.487-4.707Z"
						/>
					</svg>
					{!!limit?.unlimit && (
						<div className={css.counter}>
							<span>UNLIM</span>
							<span></span>
						</div>
					)}
					{!limit?.unlimit && (
						<div className={css.counter}>
							<span>{limit?.numberOfLimit}</span>
							<span>/100</span>
						</div>
					)}
				</div>
				{!!limit?.unlimit && <div className={css.line}></div>}
				{!limit?.unlimit && (
					<div className={css.lineContainer}>
						<div className={css.bgLine} />
						<div className={css.bgShadow} />
						<div className={css.bgGradient} />
						<div
							className={css.lineCleaner}
							style={{ width: `${100 - limit?.numberOfLimit}%` }}
						/>

						<div
							className={css.lineCleaner2}
							style={
								limit?.numberOfLimit !== 0
									? { width: `calc(${100 - limit?.numberOfLimit}% + 5px)` }
									: { width: `calc(${100 - limit?.numberOfLimit}% + 10px)` }
							}
						/>
					</div>
				)}
				{!limit?.unlimit && (
					<div className={css.time}>
						<span>{time}</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="17"
							height="17"
							fill="none"
							viewBox="0 0 17 17"
						>
							<g clipPath="url(#a23we)">
								<path
									fill="#fff"
									fillOpacity=".12"
									d="M8.5.708A7.792 7.792 0 1 0 16.292 8.5 7.756 7.756 0 0 0 8.5.708Zm4.144 10.271a.743.743 0 0 1-.602.354.672.672 0 0 1-.354-.106L7.792 8.89V4.958a.708.708 0 1 1 1.416 0V8.11l3.188 1.913a.672.672 0 0 1 .248.956Z"
								/>
								<path
									fill="url(#b23we)"
									d="M8.5.708A7.792 7.792 0 1 0 16.292 8.5 7.756 7.756 0 0 0 8.5.708Zm4.144 10.271a.743.743 0 0 1-.602.354.672.672 0 0 1-.354-.106L7.792 8.89V4.958a.708.708 0 1 1 1.416 0V8.11l3.188 1.913a.672.672 0 0 1 .248.956Z"
								/>
							</g>
							<defs>
								<linearGradient
									id="b23we"
									x1=".708"
									x2="16.292"
									y1="8.5"
									y2="8.5"
									gradientUnits="userSpaceOnUse"
								>
									<stop stopColor="#F2994A" />
									<stop
										offset="1"
										stopColor="#F2C94C"
									/>
								</linearGradient>
								<clipPath id="a23we">
									<path
										fill="#fff"
										d="M0 0h17v17H0z"
									/>
								</clipPath>
							</defs>
						</svg>
					</div>
				)}
			</div>
		</section>
	);
};

export default PredictionLimit;
