/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useEffect, useState } from "react";
import css from "../../styles/OtherPages.module.scss";
import classNames from "classnames";

const FrameDeposit = ({ menu, setMenu, percent = 0, leadData }) => {

	return (
		<div className={classNames(css.container, menu === 11 && css._show)}>
			<div
				className={css.content}
				style={{ position: "relative" }}
			>
				<section className={css.backContainer}>
					<button
						className={css.back}
						onClick={() => setMenu(1)}
					>
						SOFT
					</button>
				</section>
				<section
					className={css.framePlay}
					id="framePaly"
				>
					<iframe
						src={`${leadData.deposit}`}
						frameBorder="0"
					></iframe>
				</section>
			</div>
		</div>
	);
};

export default FrameDeposit;
