/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import css from "../../styles/OtherPages.module.scss";
import classNames from "classnames";
import ReactPlayer from "react-player";

const FramePlay = ({ menu, setMenu, leadData, videoRef, play, setIsLoading }) => {
	return (
		<div className={classNames(css.container, menu === 10 && css._show)}>
			<div
				className={css.content}
				style={{ position: "relative" }}
			>
				<section className={css.backContainer}>
					<button
						className={css.back}
						onClick={() => setMenu(1)}
					>
						SOFT
					</button>
				</section>
				<section className={css.framePlay}>
					<ReactPlayer
						url={leadData.video}
						ref={videoRef}
						playing={play}
						playsinline={true}
						width={"100%"}
						height={"100%"}
						onReady={e => setIsLoading(false)}
					/>
				</section>
			</div>
		</div>
	);
};

export default FramePlay;
