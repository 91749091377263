import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import css from "./styles/Login.module.scss";
import classNames from "classnames";

const Config = () => {
	const navigate = useNavigate();

	const [data, setData] = useState({ unlimit: false, history: [] });

	useEffect(() => {
		let config = JSON.parse(localStorage.getItem("configDemo"));

		if (!config) {
			config = { ...config, unlimit: false };
		}

		if (!config || !config.history || !config?.history?.length) {
			const arr = [];
			for (let i = 0; i < 10; i++) {
				arr.push({ coeff: "" });
			}
			config = { ...config, history: arr };
		}

		setData({ ...config });
	}, []);

	const sendData = async () => {
		localStorage.setItem("configDemo", JSON.stringify(data));
		navigate("/");
	};

	const handleChange = e => {
		const { value, name, dataset } = e.target;

		const newData = { ...data };

		const newValue = value
			.replace(/[^0-9.]/g, "")
			.replace(/(\..*)\./g, "$1")
			.replace(/(\.\d{2})\d+/g, "$1");

		if (name === "numberOfLimit" || name === "chance") {
			newData[name] = +newValue > 100 ? "100" : newValue;
		} else if (name === "history") {
			newData["history"] = [...newData.history] || [];
			newData["history"][dataset.indexNumber] =
				{ ...newData["history"][dataset.indexNumber] } || {};
			newData["history"][dataset.indexNumber].coeff = newValue;
		} else {
			newData[name] = newValue;
		}

		setData(newData);
	};
	const handleChange2 = e => {
		const { value, name } = e.target;

		const newData = { ...data };
		newData[name] = value;

		setData(newData);
	};

	return (
		<div
			className={classNames("uiContainer2")}
			style={{ overflow: "auto", height: "fit-content" }}
		>
			<div className={css.main}>
				<div
					className={classNames(css.screen, css._firstScreen)}
					style={{ height: "fit-content", paddingBottom: "100px", maxHeight: "fit-content" }}
				>
					<div className="uiBgElement" />
					<div
						className={classNames(css.signin)}
						style={{ height: "fit-content", padding: "20px 0px" }}
					>
						<div className={css.formHistory}>
							<div className={css.inputContainer}>
								<label
									className={classNames(css.inputLabel, css._coefficient)}
									htmlFor={`tgUsername`}
								>
									<span>TG Username</span>
									<input
										className={classNames(css.input, data.username?.length > 0 && css.inputActive)}
										id={`tgUsername`}
										type="text"
										name={"username"}
										placeholder={`TG Username`}
										value={data.username || ""}
										onChange={handleChange2}
									/>
								</label>
								<div className={css.inputBorder} />
							</div>
							<div className={css.inputContainer}>
								<label
									className={classNames(css.inputLabel, css._coefficient)}
									htmlFor={`tgLink`}
								>
									<span>TG Link</span>
									<input
										className={classNames(css.input, data.link?.length > 0 && css.inputActive)}
										id={`tgLink`}
										type="text"
										name={"link"}
										placeholder={`TG Link`}
										value={data.link || ""}
										onChange={handleChange2}
									/>
								</label>
								<div className={css.inputBorder} />
							</div>
							<div className={css.inputContainer}>
								<label
									className={classNames(css.inputLabel, css._coefficient)}
									htmlFor={`video`}
								>
									<span>Video Link</span>
									<input
										className={classNames(css.input, data.video?.length > 0 && css.inputActive)}
										id={`video`}
										type="text"
										name={"video"}
										placeholder={`Video Link`}
										value={data.video || ""}
										onChange={handleChange2}
									/>
								</label>
								<div className={css.inputBorder} />
							</div>
							<div className={css.inputContainer}>
								<label
									className={classNames(css.inputLabel, css._coefficient)}
									htmlFor={`deposit`}
								>
									<span>Deposit Link</span>
									<input
										className={classNames(css.input, data.deposit?.length > 0 && css.inputActive)}
										id={`deposit`}
										type="text"
										name={"deposit"}
										placeholder={`Deposit Link`}
										value={data.deposit || ""}
										onChange={handleChange2}
									/>
								</label>
								<div className={css.inputBorder} />
							</div>
						</div>
						<div className={css.formHeader}>
							<div className={css.unlimit}>
								<span>Unlimit:</span>
								<label
									className={css.switcher}
									htmlFor="unlimit"
								>
									<input
										type="checkbox"
										name=""
										id="unlimit"
										checked={data.unlimit}
										onChange={e => setData({ ...data, unlimit: e.target.checked })}
									/>
									<div className={css.switcherCircle} />
								</label>
							</div>
							{!data.unlimit && (
								<div className={css.inputContainer}>
									<label
										className={classNames(css.inputLabel, css._coefficient)}
										htmlFor={`input-id-numberOfLimit`}
									>
										<span>Number of limits</span>
										<input
											className={classNames(css.input, data.numberOfLimit > 0 && css.inputActive)}
											id={`input-id-numberOfLimit`}
											type="text"
											name="numberOfLimit"
											placeholder={"Number of limits"}
											value={data?.numberOfLimit || ""}
											onChange={handleChange}
										/>
									</label>
									<div className={css.inputBorder} />
								</div>
							)}
						</div>
						<div className={css.formChance}>
							<div className={css.inputContainer}>
								<label
									className={classNames(css.inputLabel, css._coefficient)}
									htmlFor={`input-id-chance`}
								>
									<span>Chance</span>
									<input
										className={classNames(css.input, data.chance > 0 && css.inputActive)}
										id={`input-id-chance`}
										type="text"
										name="chance"
										placeholder={"Chance"}
										value={data?.chance || ""}
										onChange={handleChange}
									/>
								</label>
								<div className={css.inputBorder} />
							</div>
						</div>
						<div className={css.formPlayCoeffs}>
							<div className={css.inputContainer}>
								<label
									className={classNames(css.inputLabel, css._coefficient)}
									htmlFor={`input-id-predict`}
								>
									<span>Predict</span>
									<input
										className={classNames(css.input, data.predict > 0 && css.inputActive)}
										id={`input-id-predict`}
										type="text"
										name="predict"
										placeholder={"Predict"}
										value={data?.predict || ""}
										onChange={handleChange}
									/>
								</label>
								<div className={css.inputBorder} />
							</div>
							<div className={css.inputContainer}>
								<label
									className={classNames(css.inputLabel, css._coefficient)}
									htmlFor={`input-id-round`}
								>
									<span>In the Round</span>
									<input
										className={classNames(css.input, data.round > 0 && css.inputActive)}
										id={`input-id-round`}
										type="text"
										name="round"
										placeholder={"In the Round"}
										value={data?.round || ""}
										onChange={handleChange}
									/>
								</label>
								<div className={css.inputBorder} />
							</div>
						</div>
						<div className={css.formHistory}>
							{data.history.map((item, index) => {
								return (
									<div
										className={css.inputContainer}
										key={`coeff${index}`}
									>
										<label
											className={classNames(css.inputLabel, css._coefficient)}
											htmlFor={`input-id-${index}`}
										>
											<span>Coefficient #{index + 1}</span>
											<input
												className={classNames(
													css.input,
													data.history[index].coeff > 0 && css.inputActive,
												)}
												id={`input-id-${index}`}
												type="text"
												name={"history"}
												data-index-number={index}
												placeholder={`Coefficient #${index + 1}`}
												value={data.history[index].coeff || ""}
												onChange={handleChange}
											/>
										</label>
										<div className={css.inputBorder} />
									</div>
								);
							})}
						</div>

						<div className={css.actions}>
							<button
								className="uiButton"
								onClick={sendData}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Config;
