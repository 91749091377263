/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import css from "../styles/Container.module.scss";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Card from "./Card";
import numeral from "numeral";
import Coeffs from "./Coeffs";
import PreviusRound from "./PreviusRound";
import PredictionLimit from "./PredictionLimit";
import Confetti from "react-confetti";

const firstDuration = 5010;
const secondDuration = 9300;

const arrTimings = [
	{ start: 1.0, end: 1.5, time: 5 },
	{ start: 1.5, end: 2.0, time: 4 },
	{ start: 2.0, end: 3.0, time: 5 },
	{ start: 3.0, end: 4.0, time: 3 },
	{ start: 4.0, end: 5.0, time: 3 },
	{ start: 5.0, end: 6.0, time: 2 },
	{ start: 6.0, end: 7.0, time: 2 },
	{ start: 7.0, end: 8.0, time: 2 },
	{ start: 8.0, end: 9.0, time: 2 },
	{ start: 9.0, end: 10.0, time: 2 },
	{ start: 10.0, end: 11.0, time: 1 },
	{ start: 11.0, end: 12.0, time: 1 },
	{ start: 12.0, end: 13.0, time: 1 },
	{ start: 13.0, end: 14.0, time: 1 },
	{ start: 14.0, end: 15.0, time: 1 },
	{ start: 15.0, end: 1000, time: 0.03 },
];

const Container = ({
	setMenu,
	menu,
	setCoeffsHistory,
	setChance,
	chance,
	setLimit,
	limit,
	handlePlayPause,
}) => {
	const navigate = useNavigate();

	const [showPopup, setShowPopup] = useState(false);
	const [confettiSizes, setConfettiSizes] = useState({ width: 0, height: 0 });
	const [lang, setLang] = useState(false);
	const [card, setCard] = useState({ card: 1 });

	const [oldCoeffs, setOldCoeffs] = useState([]);
	const [bcCoeffs, setBcCoeffs] = useState([]);
	const [timings, setTimings] = useState([]);

	useEffect(() => {
		setCoeffsHistory(oldCoeffs);
	}, [oldCoeffs]);

	const getRandomNumber = (min, max) => {
		return Math.random() * (max - min) + min;
	};

	useEffect(() => {
		const body = document.querySelector("body");
		const width = body.offsetWidth;
		const height = body.offsetHeight;

		setConfettiSizes({ width, height });

		const config = JSON.parse(localStorage.getItem("configDemo"));

		setOldCoeffs(
			config?.history
				?.filter(el => !!el.coeff)
				.map((el, index) => {
					return {
						coefficient: el.coeff,
						id: `${index}-${el.coeff}-${index}`,
						users: getRandomNumber(876, 4567),
						winners: getRandomNumber(120767, 789974),
					};
				}),
		);

		setChance(config?.chance);
		setLimit({ numberOfLimit: config?.numberOfLimit || 0, unlimit: config?.unlimit });
		setBcCoeffs([
			!!config?.predict ? Number(config?.predict) : 1,
			!!config?.round
				? Number(config?.round)
				: !!Number(config?.predict)
				? !!Number(config?.predict)
				: 1,
		]);
	}, []);

	useEffect(() => {
		const ts = getTimings(bcCoeffs);
		setTimings(ts);
	}, [bcCoeffs]);

	const getTimings = ts => {
		const newTimings = [];

		ts.forEach(coef => {
			const findIndex = arrTimings.findIndex(obj => coef >= obj.start && coef <= obj.end);

			let sumTime = 0;

			for (let i = 0; i < findIndex; i++) {
				if (i !== findIndex) {
					sumTime = sumTime + arrTimings[i].time;
				}
			}

			const x =
				(arrTimings[findIndex].end - arrTimings[findIndex].start) / arrTimings[findIndex].time;
			const y = coef - arrTimings[findIndex].start;

			sumTime = sumTime + y / x;

			newTimings.push(Math.round(sumTime * 100) / 100);
		});

		return newTimings;
	};

	return (
		<>
			{card.card === 4 && (
				<Confetti
					width={confettiSizes.width}
					height={confettiSizes.height}
					duration={1500}
				/>
			)}
			<div className={css.main}>
				<header className={css.header}>
					<section className={css.history}>
						<Coeffs oldCoeffs={oldCoeffs} />
						<PreviusRound
							users={oldCoeffs[0]?.users}
							winners={oldCoeffs[0]?.winners}
						/>
					</section>
				</header>
				<Card
					card={card}
					timings={timings}
					setTimings={setTimings}
					setOldCoeffs={setOldCoeffs}
					bcCoeffs={bcCoeffs}
					setCard={setCard}
					firstDuration={firstDuration}
					secondDuration={secondDuration}
					setLimit={setLimit}
					limit={limit}
				/>
				<section className={css.actions}>
					<div className={classNames(css.buttons, css._card_1, card.card === 1 && css._active)}>
						<button
							className={classNames(css.b, css._1)}
							onClick={() => {
								handlePlayPause();
								setCard({ card: 2, coeff: Number(bcCoeffs[0]), coeff2: Number(bcCoeffs[1]) });
							}}
						>
							<span>
								-1
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="14"
									height="22"
									fill="none"
									viewBox="0 0 14 22"
								>
									<path
										fill="#fff"
										d="m6.774 14.006.134-.95H0L7.328 0 6.56 7.412l-.134.95h6.909L6.007 21.419l.767-7.412Z"
									/>
								</svg>
								PREDICT
							</span>
						</button>
						<button
							className={classNames(css.b, css._2)}
							onClick={() => setMenu(10)}
						>
							<span>AVIATOR</span>
						</button>
					</div>
					<div className={classNames(css.buttons, css._card_2, card.card === 2 && css._active)}>
						<button
							className={classNames(css.b, css._2, css._4)}
							onClick={() => setMenu(10)}
						>
							<span>MAKE A BET</span>
						</button>
					</div>
					<div className={classNames(css.buttons, css._card_3, card.card === 3 && css._active)}>
						<button
							className={classNames(css.b, css._2, css._21)}
							onClick={() => setMenu(10)}
						>
							<span>AVIATOR</span>
						</button>
					</div>
					<div className={classNames(css.buttons, css._card_4, card.card === 4 && css._active)}>
						<button
							className={classNames(css.b, css._3, css._flicker)}
							onClick={() => setMenu(10)}
						>
							<span>CASH OUT</span>
						</button>
					</div>
					<div className={classNames(css.buttons, css._card_5, card.card === 5 && css._active)}>
						<button
							className={classNames(css.b, css._2, css._3)}
							onClick={() => setMenu(5)}
						>
							<span>INCREASE CHANCE</span>
						</button>
					</div>
					<div className={classNames(css.buttons, css._card_6, card.card === 6 && css._active)}>
						<button
							className={classNames(css.b, css._2, css._21)}
							onClick={() => setMenu(5)}
						>
							<span>INCREASE CHANCE</span>
						</button>
					</div>
					<div className={classNames(css.buttons, css._card_7, card.card === 7 && css._active)}>
						<button
							className={classNames(css.b, css._2, css._21)}
							onClick={() => setMenu(10)}
						>
							<span>AVIATOR</span>
						</button>
					</div>
				</section>
				<footer className={css.footer}>
					<PredictionLimit
						setMenu={setMenu}
						menu={menu}
						limit={limit}
					/>
					<section className={css.bottom}>
						<div className={css.progressLineContainer}>
							<div className={css.progress}>
								<div
									className={classNames(
										css.line,
										chance <= 39.99 && css._red,
										chance >= 40 && chance <= 79 && css._gold,
										chance >= 79.01 && css._green,
									)}
									style={{
										width: `${chance}%`,
									}}
								></div>
							</div>

							<div className={css.chanceContainer}>
								<div
									className={css.banner}
									onClick={() => setMenu(5)}
								>
									<span>increase chance</span>
								</div>
								<div className={css.proc}>
									<span>{numeral(chance).format("0.00")}%</span>
									<span>Chance of winning</span>
								</div>
							</div>
						</div>
					</section>
				</footer>
			</div>
		</>
	);
};

export default Container;
